.intro {
  height: 95%;
}
.intro .swiper-slide {
  height: 100%;
  background-position: 0 -5px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('intro.png');
  padding: 2em;
}
.intro .swiper-slide p {
  margin-top: 5em;
  line-height: 1.5;
  height: 300px;
}
