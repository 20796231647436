.mentalHealth {
  --background: #E6F0FC;
  --background-hover: #008CE6;
  
  border-color: #A3D2D9;
  --ion-border-color: #A3D2D9;

  color: #008CE6;
  --ion-text-color: #008CE6;
}
ion-button.mentalHealth:hover {
  color: white;
}

.reproductiveHealth {
  --background: #FFD7B3;
  --background-hover: #de8538;
  
  border-color: #de8538;
  --ion-border-color: #de8538;

  color: #de8538;
  --ion-text-color: #de8538;
}
ion-button.reproductiveHealth:hover {
  color: white;
}

.collegeAccess {
  --background: #DDFCAD;
  --background-hover: #82846D;
  
  border-color: #82846D;
  --ion-border-color: #82846D;

  color: #82846D;
  --ion-text-color: #82846D;
}
ion-button.collegeAccess:hover {
  color: white;
}
